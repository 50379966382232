<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="啟用狀態"
                                    v-model="activeList"
                                    @getValue="val=>search.active=val"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="商品編號"
                                   v-model="search.code"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="商品名稱"
                                   v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <mdb-input type="checkbox"
                           id="show_cost"
                           label="檢視成本"
                           v-model="show_cost"></mdb-input>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th style="width:4em">#</th>
                            <th class="text-nowrap"
                                style="cursor:pointer;width:9.5em"
                                @click="sort_data('code')">
                              商品編號
                              <i class="ml-2 fa"
                                 :class="{'fa-sort':search.sortcolumn!='code','fa-sort-up':search.sortcolumn=='code'&&search.sort=='asc','fa-sort-down':search.sortcolumn=='code'&&search.sort=='desc'}"></i>
                            </th>
                            <th class="text-nowrap"
                                style="cursor:pointer;"
                                @click="sort_data('name')">
                              商品名稱
                              <i class="ml-2 fa"
                                 :class="{'fa-sort':search.sortcolumn!='name','fa-sort-up':search.sortcolumn=='name'&&search.sort=='asc','fa-sort-down':search.sortcolumn=='name'&&search.sort=='desc'}"></i>
                            </th>
                            <th class="text-nowrap text-center"
                                style="cursor:pointer;width:7.5em"
                                @click="sort_data('cost')"
                                v-show="show_cost">
                              成本
                              <i class="ml-2 fa"
                                 :class="{'fa-sort':search.sortcolumn!='cost','fa-sort-up':search.sortcolumn=='cost'&&search.sort=='asc','fa-sort-down':search.sortcolumn=='cost'&&search.sort=='desc'}"></i>
                            </th>
                            <th class="text-nowrap"
                                style="cursor:pointer;width:7.5em"
                                @click="sort_data('price')">
                              建議售價
                              <i class="ml-2 fa"
                                 :class="{'fa-sort':search.sortcolumn!='price','fa-sort-up':search.sortcolumn=='price'&&search.sort=='asc','fa-sort-down':search.sortcolumn=='price'&&search.sort=='desc'}"></i>
                            </th>
                            <th style="width:8.5em">庫存</th>
                            <th class="px-0" style="width:9em"></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody>
                        <tr v-for="(p,pk) in data.productList"
                            :key="`p_${pk}`">
                            <th data-title="#">
                                {{(data.nowPage-1)*10+pk+1}}
                            </th>
                            <td data-title="商品編號">{{p.code}}&nbsp;</td>
                            <td data-title="商品名稱">{{p.name}}</td>
                            <td data-title="成本" class="text-right"
                                v-show="show_cost">
                                {{$numeral(p.cost).format('0,0')}}&nbsp;
                            </td>
                            <td data-title="建議售價" class="text-right">
                              {{$numeral(p.price).format('0,0')}}
                            </td>
                            <td data-title="庫存">
                                <p v-for="(s,sk) in p.stock"
                                   :key="`p_${p.id}_s_${sk}`">
                                    {{storeKeyList[s.id_store] ? storeKeyList[s.id_store].name : ''}}：{{$numeral(s.stock).format(",")}}</p>
                            </td>
                            <td class="text-right text-md-left">
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="$router.push(`/product/edit/?id_product=${p.id}`)">
                                    <i class="fa fa-edit"></i>編輯
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"
                                scope="row"
                                v-show="data.productList.length==0">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;searchdata();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    page,
  },
  data() {
    return {
      search: {
        active: "",
        code: "",
        name: "",
        sortcolumn: "",
        sort: "",
        page: 1,
      },
      show_cost: false,
      activeList: [],
      search_change: false,
      data: {
        warehouse: [],
        productList: [],
        nowPage: 1,
        totalPage: 0,
      },
    };
  },
  mounted() {
    let vue = this;
    vue.put_search();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
    query() {
      this.put_search();
    },
  },
  computed: {
    storeKeyList() {
      let output = {};
      this.data.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
  },
  methods: {
    put_search() {
      let vue = this;
      // 把篩選的資料放到變數裡面
      for (let i in vue.search) {
        vue.search[i] = vue.query[i] ? vue.query[i] : "";
      }
      vue.activeList = [
        { text: "全部", value: "", selected: false },
        { text: "啟用", value: "1", selected: false },
        { text: "停用", value: "0", selected: false },
      ];
      vue.activeList.map((item) => {
        item.selected = item.value == vue.search.active;
        return item;
      });
      vue.getData();
    },
    sort_data(data) {
      //
      this.search.page = 1;
      if (this.search.sort == "" ||
          this.search.sortcolumn != data ||
          (this.search.sort == "desc" && this.search.sortcolumn == data)) {
        this.search.sortcolumn = data;
        this.search.sort = "asc";
      } else if (this.search.sort == "asc") {
        this.search.sortcolumn = data;
        this.search.sort = "desc";
      } else {
        this.search.sortcolumn = "";
        this.search.sort = "";
      }

      this.searchdata();
    },
    // 搜尋資料
    searchdata() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
        vue.getData();
      }
      vue.search_change = false;
    },
    getData() {
      let vue = this,
        url = "product/index/?";
      for (let i in vue.search) {
        url += `${i}=${vue.search[i]}&`;
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: url,
          },
        })
        .then((res) => {
          Object.assign(vue.data, res.data);
        });
    },
  },
};
</script>